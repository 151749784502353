<template>
    <div class="footer-bottom">
       <!-- <div class="site-info">
           <a v-if="copyRight.icp" class="foot-link" href="" target="_blank">首页</a> 
		   <a v-if="copyRight.icp" class="foot-link" href="" target="_blank">产品中心</a> 
		   <a v-if="copyRight.icp" class="foot-link" href="" target="_blank">源码下载</a> 
		   <a v-if="copyRight.icp" class="foot-link" href="" target="_blank">授权价格</a> 
		   <a v-if="copyRight.icp" class="foot-link" href="" target="_blank">解决方案</a> 
		   <a v-if="copyRight.icp" class="foot-link" href="" target="_blank">商城资讯</a> 
		   <a v-if="copyRight.icp"  style="border: none;" class="foot-link" href="" target="_blank">服务支持</a>
        </div> -->
		<div class="footer-top">
			<div class="footer-div">
				<span class="stitle">蔚然錦合貿易有限公司（香港）</span>
				<p>
					
					<a  style="border: none;" class="footer-link" href="" target="_blank"> 中國香港 旺角區好景商業中心花園街2-16號</a>
					 
				</p>
				<p>
					
					  
					<a   style="border: none;" class="footer-link"  target="_blank">電話: +852 615 88111</a>
				</p>
				<p>
					<a  style="border: none;" class="footer-link"  target="_blank"> 傳真: +852 615 88111</a>
					
				</p>
				<p>
					<a  href="mailto://88@fgywp.com" style="border: none;" class="footer-link"   target="_blank">
						
						<span>電郵: 88@fgywp.com</span>
					</a>
				</p>
			</div>
			<div class="footer-div">
				<span class="stitle">大陸分公司</span>
				<p>
					
					<a   style="border: none;" class="footer-link" href="" target="_blank">長沙豪銘科技有限公司 版權所有©2024-2030 並保留所有權利 </a>
					 
				</p>
				<p>
					
				  
					<a   style="border: none;" class="footer-link"  target="_blank">公司電話：400-869-7738</a>
				</p>
				<p>
					<a  style="border: none;" class="footer-link"  target="_blank">公司地址：湖南省長沙市開福區清水塘街道芙蓉中路壹段479號建鴻達現代城1406房A區665號</a>
					
				</p>
				<p>
					<a  href="https://beian.miit.gov.cn" style="border: none;" class="footer-link"   target="_blank">
						
						<span>備案號：{{ copyRight.icp }}</span>
					</a>
				</p>
			</div>
		</div>
		<div class="footer-foot">
			<p>本免责声明的条款适用于本网站：mingaoh.com 本网站的使用应受香港特别行政区（"香港特区"）法律的管辖任何争议均应受香港特别行政区法院的非专属管辖。</p>
		</div>
    </div>
</template>

<script>
    import { copyRight } from "@/api/website"
    import { mapGetters } from "vuex"
    export default {
        props: {},
        data() {
            return {}
        },
        computed: {},
        created() {
            this.$store.dispatch("site/copyRight")
        },
        mounted() {},
        watch: {},
        methods: {},
        computed: {
            ...mapGetters(["copyRight", "siteInfo"])
        }
    }
</script>

<style scoped lang="scss">
    .footer-bottom {
        width: 100%;
        height: 85px;
        margin: 0 auto;
        padding: 20px 0;
		display: flex;
		flex-direction: column;
		align-items:center;
		.footer-top{
			display: flex;
			justify-content:space-evenly;
			align-items:center;
			.foot-link {
				padding:0 10px;
				border-right:1px solid #b3b3b3;
				height: 14px; 
				line-height: 14px;
				margin:120px 0 10px 0;
				color: #b3b3b3;
			}
			.footer-link {
				padding:0 10px;
				border-right:1px solid #b3b3b3;
				margin-bottom: 10px;
				p {
					
				}
			}
			.footer-div{
				display:flex;flex-direction:column;
				justify-content:flex-start;
				align-items:center;
				span.stitle {font-size:16px;font-weight:bold;}
				p {
					margin: 0;
					width: 100%;
					box-sizing: border-box;
					text-align: center;
					
					img {
						width: 20px;
						height: 20px;
						margin-right: 5px;
					}
					.footer-link, .el-link.el-link--default {
					   color: #b3b3b3 !important;
					}
					.footer-link:hover, .el-link.el-link--default:hover {
						color: $base-color;
					}
				}
			}
			.site-info {
				// display: flex;
				// justify-content: center;
				// align-items: center;
				text-align: center;
				p {
					width: auto;
					margin: 0 10px;

					i {
						vertical-align: bottom;
						margin-right: 5px;
					}
				}
			}
		.footer_foot{
			width:100%;
			padding:50px 0;
			p{font-size:14px;}
		 }
		}
    }
</style>
